var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-3"},[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between mb-4"},[_c('div',{staticClass:"top-header mb-0 font-weight-bold"},[_vm._v(" "+_vm._s("Tasks")+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"primary","to":"/tasks/import_policy_payments"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cloud-upload")]),_vm._v(" Import")],1),_c('v-btn',{staticClass:"mx-2",attrs:{"depressed":"","color":"primary","to":"/tasks/backups"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-folder-upload")]),_vm._v(" Backups")],1),_c('v-btn',{attrs:{"depressed":"","color":"warning","to":"/tasks/logs"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clipboard-alert")]),_vm._v(" Logs")],1)],1),_c('v-card',{staticClass:"pa-1"},[_c('div',{staticClass:"d-flex justify-end my-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":_vm.getTask}},on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v("Reload")])])],1),[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"options":_vm.options,"headers":_vm.headers,"footer-props":_vm.footerProps,"single-expand":"","show-expand":"","item-key":"uuid","expanded":_vm.expanded,"items":_vm.tasks,"server-items-length":_vm.totalTasks,"items-per-page":10},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.createdAt))+" ")]}},{key:"item.taskType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getType(item.taskType))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[(item.setting)?[_c('div',{staticClass:"d-flex justify-center"},[_vm._v("Settings:")]),_c('v-divider',{staticClass:"my-2"}),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((Object.entries(item.setting)),function(ite,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"4"}},[_c('strong',[_vm._v(_vm._s(item.taskType == "INSERT_PAYMENTS" ? _vm.getNamesP(ite.toString().split(",")[0]) : _vm.getNames(ite.toString().split(",")[0]))+": ")]),_vm._v(_vm._s(_vm.getDataShow(ite))+" ")])}),1),_c('v-divider',{staticClass:"mb-2"})]:_vm._e()],2)],1)]}}],null,true)})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }